<template>
  <div ref="addvest">
    <el-dialog
      title="马甲添加"
      :visible.sync="dialogFormVisible"
      width="40%"
      top="10rem"
      border-radius="30px"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="马甲名称" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.name"
            autocomplete="off"
            placeholder="请输入马甲名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="名称缩写" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.companyName"
            autocomplete="off"
            placeholder="请输入名称缩写"
          ></el-input>
        </el-form-item>
        <el-form-item label="渠道" :label-width="formLabelWidth">
          <el-select
            v-model="form.channel"
            placeholder="请选择马甲渠道"
            size="small"
          >
            <el-option
              v-for="item in check"
              :key="item.num"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-select
            v-model="form.status"
            placeholder="请选择马甲状态"
            size="small"
          >
            <el-option label="正常" value="正常"></el-option>
            <el-option label="禁用" value="禁用"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="appKey" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.accountName"
            autocomplete="off"
            placeholder="请输入appKey"
          ></el-input>
        </el-form-item>
        <el-form-item label="appSecret" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.password"
            autocomplete="off"
            show-password
            placeholder="请输入appSecret"
          ></el-input>
        </el-form-item>
        <el-form-item label="sessionKey" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.sessionKey"
            autocomplete="off"
            placeholder="请输入sessionKey"
          ></el-input>
        </el-form-item>
        <el-form-item label="vendor" :label-width="formLabelWidth">
          <el-input
            size="small"
            v-model="form.vendor"
            autocomplete="off"
            placeholder="请输入vendor"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="mini" @click="close">取消</el-button>
            <el-button size="mini" type="primary" @click="onSubmit"
              >保存</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getVest: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "",
        canal: [],
        status: "",
        companyName: "",
        accountName: "",
        password: "",
        vender: "",
        sessionKey:'',
      },
      formLabelWidth: "100px",
      check: [
        { name: "艺龙", num: 1 },
        { name: "美团", num: 2 },
        { name: "飞猪", num: 3 },
        { name: "去哪儿", num: 4 },
        { name: "携程", num: 5 },
        { name: "百度", num: 6 },
        { name: "萝卜", num: 7 },
        { name: "天下房仓", num: 8 },
        { name: "龙腾捷旅", num: 9 },
      ],
      checkList: [],
    };
  },
  methods: {
    open() {
      this. form={
        name: "",
        canal: [],
        status: "",
        companyName: "",
        accountName: "",
        password: "",
        vender: "",
        sessionKey:'',
      }
      this.dialogFormVisible = true;
    },
    //确认保存
    onSubmit() {
      this.addVest();
      this.dialogFormVisible = false;
      this.form = {};
    },
    close() {
      this.dialogFormVisible = false;
      this.form = {};
    },
    //马甲添加
    addVest() {
      this.form.canal = this.checkList;
      this.$axios({
        method: "post",
        url: "/otaHotel/horseVest/creat",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          horseName: this.form.name,
          platformId:
            this.form.channel == "艺龙"
              ? "1"
              : this.form.channel == "飞猪"
              ? "2"
              : this.form.channel == "美团"
              ? "3"
              : this.form.channel == "去哪儿"
              ? "4"
              : this.form.channel == "携程"
              ? "5"
              : this.form.channel == "百度"
              ? "6"
              : this.form.channel == "萝卜"
              ? "7"
              : this.form.channel == "天下房仓"
              ? "8"
              : this.form.channel == "龙腾捷旅"
              ? "9"
              : "",
          status: this.form.status == "正常" ? "1" : "0",
          sessionKey: this.form.sessionKey,
          accountName: this.form.accountName,
          passwd: this.form.password,
          companyName: this.form.companyName,
          vendor: this.form.vendor,
        },
      })
        .then((res) => {
        //  console.log(res, "点击保存按钮的响应数据");
          if (res.data.data == "新增成功") {
            this.$message({
              message: "恭喜您已成功添加马甲!",
              type: "success",
            });
            this.getVest();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input--small {
  width: 292.6px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 80%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>