<template>
  <div ref="revisevest">
    <el-dialog
      title="马甲修改"
      :visible.sync="dialogFormVisible"
      width="35%"
      top="10rem"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="马甲名称" :label-width="formLabelWidth">
          <el-input size="small" v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="名称缩写" :label-width="formLabelWidth">
          <el-input size="small" v-model="form.companyName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="渠道" :label-width="formLabelWidth">
          <el-select v-model="form.channel" placeholder size="small">
            <el-option v-for="item in check" :key="item.num" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-select v-model="form.status" placeholder size="small">
            <el-option label="正常" value="正常"></el-option>
            <el-option label="禁用" value="禁用"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="appKey" :label-width="formLabelWidth">
          <el-input size="small" v-model="form.accountName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="appSecret" :label-width="formLabelWidth">
          <el-input size="small" v-model="form.password" autocomplete="off" show-password></el-input>
        </el-form-item>
        <el-form-item label="sessionKey" :label-width="formLabelWidth">
          <el-input size="small" v-model="form.sessionKey" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="vendor" :label-width="formLabelWidth">
          <el-input size="small" v-model="form.vendor" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="btn">
            <el-button size="mini" @click="close">取消</el-button>
            <el-button size="mini" type="primary" @click="onSubmit">保存</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getVest: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "",
        channel: "",
        status: "",
        companyName: "",
        accountName: "",
        password: "",
        vendor: "",
        seesionKey: "",
        sessionKey: "",
      },
      formLabelWidth: "100px",
      check: [
        { name: "艺龙", num: 1 },
        { name: "美团", num: 2 },
        { name: "飞猪", num: 3 },
        { name: "去哪儿", num: 4 },
        { name: "携程", num: 5 },
        { name: "百度", num: 6 },
        { name: "萝卜", num: 7},
        { name: "天下房仓", num: 8},
        { name: "龙腾捷旅", num: 9},
      ],
      // checkList: [],
      id: [],
    };
  },
  methods: {
    // 数据回填
    open(row) {
      this.getVest(); // 重绘视图
      // this.form = {};
      this.dialogFormVisible = true;
      this.id = row;
      //   console.log(this.id, "点击修改 数据回显");
      this.form.name = this.id.horseName;
      this.form.companyName = this.id.companyName;
      this.form.accountName = this.id.accountName;
      this.form.password = this.id.passwd;
      this.form.sessionKey = this.id.sessionKey;
      this.form.vendor = this.id.vendor;

      
      // this.form.channel =
      //   this.id.platformId == "1"
      //     ? "艺龙"
      //     : this.id.platformId == "2"
      //     ? "飞猪"
      //     : this.id.platformId == "3"
      //     ? "美团"
      //     : this.id.platformId == "4"
      //     ? "去哪儿"
      //     : this.id.platformId == "5"
      //     ? "携程"
      //     : "百度";

          if( this.id.platformId == "1"){
            this.form.channel = "艺龙";
          }else if( this.id.platformId == "2"){
            this.form.channel = "飞猪";
          }else if( this.id.platformId == "3"){
            this.form.channel = "美团";
          }else if( this.id.platformId == "4"){
            this.form.channel = "去哪儿";
          }else if( this.id.platformId == "5"){
            this.form.channel = "携程";
          }else if( this.id.platformId == "6"){
            this.form.channel = "百度";
          }else if( this.id.platformId == "7"){
            this.form.channel = "萝卜";
          }else if( this.id.platformId == "8"){
            this.form.channel = "天下房仓";
          } else if( this.id.platformId == "9"){
            this.form.channel = "龙腾捷旅"; 
          }



      this.form.status =
        this.id.status == "1" ? "正常" : this.id.status == "0" ? "禁用" : "";
      //  console.log(this.form, "表单");
    },
    //确认保存
    onSubmit() {
      this.reviseVest();
      // this.getVest(); // 重绘视图
      this.dialogFormVisible = false;
    },
    close() {
      this.dialogFormVisible = false;
    },
    //马甲修改
    reviseVest() {
      // this.form.canal = this.checkList;
      this.$axios({
        method: "post",
        url: "/otaHotel/horseVest/creat",
        headers: {
          "Content-Type": "application/json",
        },
        // dataType: "json",
        data: {
          id: this.id.id,
          horseName: this.form.name,
          platformId:
            this.form.channel == "艺龙"
              ? "1"
              : this.form.channel == "飞猪"
              ? "2"
              : this.form.channel == "美团"
              ? "3"
              : this.form.channel == "去哪儿"
              ? "4"
              : this.form.channel == "携程"
              ? "5"
              : this.form.channel == "百度"
              ? "6"
              : this.form.channel == "萝卜"
              ? "7"
              : this.form.channel == "天下房仓"
              ? "8"
              : this.form.channel == "龙腾捷旅"
              ? "9"
              : "",
          status: this.form.status == "正常" ? "1" : "0",
          description: this.form.description,
          sessionKey: this.form.sessionKey,
          accountName: this.form.accountName,
          passwd: this.form.password,
          companyName: this.form.companyName,
          vendor: this.form.vendor,
        },
      })
        .then((res) => {
          //    console.log(res, "修改马甲响应数据");
          // this.form = {};
          if (res.data.data == "修改成功") {
            this.$message({
              message: "恭喜您已成功修改数据!",
              type: "success",
            });
            this.getVest(); // 重绘
          }
        })
        .catch((error) => {
          console.log(error, "捕捉错误信息");
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input--small {
  width: 292.6px;
}
/deep/.el-dialog {
  border-radius: 10px;
}
.el-form {
  width: 87%;
  margin: auto;
}
.btn {
  display: flex;
  justify-content: space-around;
}
/deep/.el-input__inner {
  background-color: #f1f1f1;
}
</style>